<template>
    <div class="support">
        <div class="support__title">
            <h1 class="support--title">{{ $t('support.suporte') }}</h1>
        </div>
        
        <div class="support--section">
            <div class="support--section--left">
                <p class="support--section--left--question">{{ $t('support.perguntasFrequentes') }}</p>

                <details v-for="(question, index) in questions"
                         class="support--section--left--collapse" :key="index">
                    <summary class="support--section--left--collapse--title">
                        <div id="teste" class="support--section--left--collapse--title--state" >
                            <svg width="21" height="10" viewBox="0 0 21 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_145_4230)">
                                    <path d="M3.42422 -2.38243e-05L1.94922 1.47498L10.1992 9.72498L18.4492 1.47498L16.9742 -2.36627e-05L10.1992 6.77498L3.42422 -2.38243e-05Z" fill="var(--buttons_color)"/>
                                </g>
                            </svg>
                        </div>

                        {{ question.title }}
                    </summary>
                    <p class="support--section--left--collapse--details"
                       v-html="question.description"></p>
                </details>
            </div>
            <div class="support--section--right" style="display: none;">
                <p class="support--section--right--title">{{ $t('support.entreEmContato') }}</p>
                <p class="support--section--right--details">{{ $t('support.noEncontrouO') }}</p>  
            </div>
            <a v-if="this.switch.support_type=='url'" :href="this.switch.support_url" target="_blank" class="support--section--floatcomponent">
                    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_1798_12462)">
                        <path d="M8 15.1343C6.14348 15.1343 4.36301 14.3968 3.05025 13.084C1.7375 11.7713 1 9.99079 1 8.13428C1 6.27776 1.7375 4.49728 3.05025 3.18453C4.36301 1.87178 6.14348 1.13428 8 1.13428C9.85652 1.13428 11.637 1.87178 12.9497 3.18453C14.2625 4.49728 15 6.27776 15 8.13428C15 9.99079 14.2625 11.7713 12.9497 13.084C11.637 14.3968 9.85652 15.1343 8 15.1343ZM8 16.1343C10.1217 16.1343 12.1566 15.2914 13.6569 13.7911C15.1571 12.2908 16 10.256 16 8.13428C16 6.01255 15.1571 3.97771 13.6569 2.47742C12.1566 0.977132 10.1217 0.134277 8 0.134277C5.87827 0.134277 3.84344 0.977132 2.34315 2.47742C0.842855 3.97771 0 6.01255 0 8.13428C0 10.256 0.842855 12.2908 2.34315 13.7911C3.84344 15.2914 5.87827 16.1343 8 16.1343V16.1343Z" fill="var(--buttons_color)"/>
                        <path d="M5.2551 5.92028C5.25373 5.95257 5.25898 5.9848 5.27053 6.01499C5.28208 6.04518 5.29968 6.07269 5.32225 6.09583C5.34482 6.11896 5.37189 6.13723 5.40179 6.14952C5.43168 6.16181 5.46378 6.16785 5.4961 6.16728H6.3211C6.4591 6.16728 6.5691 6.05428 6.5871 5.91728C6.6771 5.26128 7.1271 4.78328 7.9291 4.78328C8.6151 4.78328 9.2431 5.12628 9.2431 5.95128C9.2431 6.58628 8.8691 6.87828 8.2781 7.32228C7.6051 7.81128 7.0721 8.38228 7.1101 9.30928L7.1131 9.52628C7.11415 9.59189 7.14095 9.65445 7.18772 9.70047C7.23449 9.7465 7.29748 9.77229 7.3631 9.77228H8.1741C8.2404 9.77228 8.30399 9.74594 8.35087 9.69905C8.39776 9.65217 8.4241 9.58858 8.4241 9.52228V9.41728C8.4241 8.69928 8.6971 8.49028 9.4341 7.93128C10.0431 7.46828 10.6781 6.95428 10.6781 5.87528C10.6781 4.36428 9.4021 3.63428 8.0051 3.63428C6.7381 3.63428 5.3501 4.22428 5.2551 5.92028V5.92028ZM6.8121 11.6833C6.8121 12.2163 7.2371 12.6103 7.8221 12.6103C8.4311 12.6103 8.8501 12.2163 8.8501 11.6833C8.8501 11.1313 8.4301 10.7433 7.8211 10.7433C7.2371 10.7433 6.8121 11.1313 6.8121 11.6833Z" fill="var(--buttons_color)"/>
                    </g>
                    <defs>
                        <clipPath id="clip0_1798_12462">
                            <rect width="16" height="16" fill="white" transform="translate(0 0.134277)"/>
                        </clipPath>
                    </defs>
                </svg>                    
                <span>Ajuda</span>
            </a>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from "vuex";
    import axios from "axios";

    export default {
        name: 'Support',
        computed: {
            ...mapGetters([
                'logged', 'token', 'switch'
            ]),
        },
        data() {
            return {
                questions: [],
            };
        },
        methods: {
            async getFaq() {
                try {
                    const res = await axios.get(this.$url_api + 'api/getFaq',{headers:{'language': this.$i18n.locale,}});
                    const titles = [...res.data.data.title]
                    const descriptions = [...res.data.data.description]
                    let q = []
                    for (let i = 0; i < titles.length; i++) {
                        console.log('caindo no loop')
                        q[i] = {"title": titles[i], 'description': descriptions[i]};
                    }
                    console.log(q)
                    this.questions = [...q]

                }catch (e){
                    console.log(e)
                }
            },
        },
        mounted() {
            this.getFaq();
            let root_ = this;
            setTimeout(()=>{
                if (root_.$route.name === "Support") {
                    try {
                        if (root_.switch.support_type == 'zendesk')
                            root_.$zendesk.show();
                    }catch (e) {
                        e;
                    }
                }
            },100);
        },
        beforeDestroy() {
            this.$zendesk.hide();
        }
    }
</script>